.anchor {
  &::after {
    width: 0%;
  }

  &:hover::after {
    visibility: visible;
    width: 100%;
  }
}
